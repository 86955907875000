import useEventSubdomain from "../../hooks/use-event-subdomain";

/*eslint-disable*/
export const getEventNameFromUrl = (isDomain = true): string | null => {
  const url = window.location.href;
  return isDomain ? useEventSubdomain(url) : _getEventNameAsPath(url);
};
/*eslint-enable*/

const _getEventNameAsPath = (url: string): string | null => {
  const match = url.match(/^http:\/\/([^.]+)\.(?:[a-zA-Z].com|localhost:3000)\/([^/]+)\//);

  if (match) {
    const domain = match[1]; // For now we are catching only the domain as the event name
    return domain;
  } else {
    return null;
  }
};
